.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.wordCloudSection {
    padding: 1em;
}

span.row {
    display: block;
}

button.fixed.bottom.right {
    position: fixed;
    right: 1em;
    bottom: 1em;
    z-index: 1;
}

button.fixed.top.right {
    position: fixed;
    right: 1em;
    top: 1em;
    z-index: 1;
}


@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.control-panel {
    border: 2px solid rgba(34, 36, 38, .15);
    padding: 1em;
    border-radius: 1em;
    margin: 1em 0;
}

.eoi-index g[class*='axis-left'], g[class*='axis-right'], g[class*='meso-axis'] {
    display: none;
}

.filter-container {
    width: 100%;
    display: flex;
    padding: .5em 0 .5em 0;
}

.table-container {
    overflow-x: scroll;
    scroll-behavior: smooth;
}

.stat-box {
    display: inline-block;
    width: 25%;
    margin: 0 .5em 0 0;
    border-radius: .5em;
    border: 1px solid #d4d4d5;
    flex: 1;
    *display: inline;
    zoom: 1;
    text-align: center;
}

.stretch {
    width: 100%;
    display: inline-block;
    font-size: 0;
    line-height: 0
}

.radiopick {
    margin-left: 1em;
}

.home {
    /*background: rgb(2, 0, 36);*/
    /*background: linear-gradient(0deg, rgba(2, 0, 36, 1) 0%, rgba(2, 2, 88, 1) 52%, rgba(114, 18, 226, 1) 100%);*/
    /*height: 100vh;*/
    margin: 1em;
    padding: 1em;
}

.home .middle {
    position: relative;
    height: 100vh;
    width: 100%;
    display: table;
}

.home .middle blockquote {
    color: #838383;
    text-align: left;
    padding: 2em;
    display: table-cell;
    vertical-align: middle;
    font-size: 3vw;
    font-style: oblique;
    font-family: serif;
    line-height: 1em;
}


@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


/* Mobile */
@media only screen and (max-width: 767px) {
    [class*="mobile hidden"],
    [class*="tablet only"]:not(.mobile),
    [class*="computer only"]:not(.mobile),
    [class*="large screen only"]:not(.mobile),
    [class*="widescreen only"]:not(.mobile),
    [class*="or lower hidden"] {
        display: none !important;
    }
}

/* Tablet / iPad Portrait */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    [class*="mobile only"]:not(.tablet),
    [class*="tablet hidden"],
    [class*="computer only"]:not(.tablet),
    [class*="large screen only"]:not(.tablet),
    [class*="widescreen only"]:not(.tablet),
    [class*="or lower hidden"]:not(.mobile) {
        display: none !important;
    }
}

/* Computer / Desktop / iPad Landscape */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    [class*="mobile only"]:not(.computer),
    [class*="tablet only"]:not(.computer),
    [class*="computer hidden"],
    [class*="large screen only"]:not(.computer),
    [class*="widescreen only"]:not(.computer),
    [class*="or lower hidden"]:not(.tablet):not(.mobile) {
        display: none !important;
    }
}

/* Large Monitor */
@media only screen and (min-width: 1200px) and (max-width: 1919px) {
    [class*="mobile only"]:not([class*="large screen"]),
    [class*="tablet only"]:not([class*="large screen"]),
    [class*="computer only"]:not([class*="large screen"]),
    [class*="large screen hidden"],
    [class*="widescreen only"]:not([class*="large screen"]),
    [class*="or lower hidden"]:not(.computer):not(.tablet):not(.mobile) {
        display: none !important;
    }
}

/* Widescreen Monitor */
@media only screen and (min-width: 1920px) {
    [class*="mobile only"]:not([class*="widescreen"]),
    [class*="tablet only"]:not([class*="widescreen"]),
    [class*="computer only"]:not([class*="widescreen"]),
    [class*="large screen only"]:not([class*="widescreen"]),
    [class*="widescreen hidden"],
    [class*="widescreen or lower hidden"] {
        display: none !important;
    }
}